@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@import "~font-awesome/css/font-awesome.css";

@tailwind utilities;

h1,
h2,
h3 {
  font-family: "Roboto";
  @apply bg-clip-text text-transparent bg-gradient-to-r from-primary-800 to-primary-300;
}

p {
  @apply text-lg text-gray-500;
}

li {
  @apply text-gray-500;
}

.xl-button {
  @apply text-lg uppercase font-extrabold w-auto flex justify-center items-center;
}

a {
  @apply text-primary-400;
}

a:hover {
  @apply text-primary-300;
}

.ant-btn-dangerous {
  background-color: #f6921e;
  border: white 1px solid;
  color: white;
}

.ant-btn-dangerous:hover {
  color: #f6921e;
  border-color: #f6921e;
}

.ant-btn-dangerous:focus {
  color: #f6921e;
  border-color: #f6921e;
}

svg {
  vertical-align: baseline;
}
